import React from "react";
import { useLocation } from "react-router";

import "./HeadwayWidget.css";

const HeadwayWidget = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Only initilize if DTC App
    if (location.pathname.includes("/dtc")) {
      // Headway Widget Config
      let widgetConfig = {
        selector: ".headway-badge",
        account: "JrnjYJ",
      };

      // Initialize Widget with every rerender
      // https://docs.headwayapp.co/widget
      window.Headway.init(widgetConfig);
      // log.debug("%c Refreshing Headway Widget ", "background: #222; color: #bada55");
    }
  });

  // Only show Widget if DTC app
  if (!location.pathname.includes("/dtc")) {
    return null;
  }

  return (
    <>
      <span className="headway-badge" id="headway-badge"></span>
    </>
  );
};

export default HeadwayWidget;

const FirebaseConfig = {
    apiKey: "AIzaSyDoES_Abq9nh1VDx5iU1lrg5u4opX7Pqdg",
    authDomain: "enolytics.firebaseapp.com",
    databaseURL: "https://enolytics.firebaseio.com",
    projectId: "enolytics",
    storageBucket: "enolytics.appspot.com",
    messagingSenderId: "243285131779",
    appId: "1:243285131779:web:93753201c3f2fa33f90436",
    measurementId: "G-P5YW9GYZ7B"
};

export default FirebaseConfig;
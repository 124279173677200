import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import SignIn from "../components/Auth/SignIn";
import SSO from "../components/Auth/SSO";
import PasswordForget from "../components/Auth/PasswordForget";
import FinishSignIn from "../components/Auth/FinishSignIn";

export default () => (
  <Routes>
    <Route path="signin" element={<SignIn />} />
    <Route path="forgotpwd" element={<PasswordForget />} />
    <Route path="finishSignIn" element={<FinishSignIn />} />
    <Route path="/sso/jwt/login" element={<SSO />} />
    <Route path="/" element={<SignIn />} />
    <Route path="/*" element={<Navigate to="/" replace />} />
  </Routes>
);

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/User";
import { FirebaseContext } from "../../context/Firebase";
import { LoggingContext } from "../../context/Logger";
import SignInPage from "./SignIn";
import { EuiPage, EuiPageBody, EuiFlexGroup, EuiPanel, EuiLoadingSpinner } from "@elastic/eui";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SSO = () => {
  const history = useNavigate();
  const { log } = React.useContext(LoggingContext);
  let { functions } = React.useContext(FirebaseContext);
  const { user } = React.useContext(UserContext);

  let query = useQuery();
  log.debug("Query:", query);
  log.debug("location", useLocation().search);

  const getSSOToken = (params) => {
    log.debug("getSSOToken executed...");
    return new Promise((resolve, reject) => {
      const token = functions.httpsCallable("getSSOToken");
      token(params).then((result) => resolve(result.data));
    });
  };

  if (!user) {
    return <SignInPage redirect={false} />;
  } else {
    let ssoPayload = {
      client_id: query.get("client_id"),
      state: query.get("state"),
      nonce: query.get("nonce"),
      grant_type: query.get("grant_type"),
      scope: query.get("scope"),
    };

    getSSOToken(ssoPayload).then((data) => {
      log.debug(data);
      log.debug("Redirect to SSO Destination Here");
      log.debug(`${query.get("redirect_uri")}?state=${ssoPayload.state}&id_token=${data.jwt}`);
      window.location.href = `${query.get("redirect_uri")}?state=${ssoPayload.state}&id_token=${data.jwt}`;
    });

    return (
      <>
        <EuiPage style={{ width: "100%", height: "100%" }}>
          <EuiPageBody component="div">
            <EuiFlexGroup justifyContent="spaceAround" alignItems="center" gutterSize="none">
              <EuiPanel hasBorder={true} grow={false}>
                <EuiLoadingSpinner size="xl" />
              </EuiPanel>
            </EuiFlexGroup>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
  //Check to see if user logged in
  // if logged in get token and redirect to redirecturl
  // if not logged in show the login page with special redirect to this page once logged in.
};

export default SSO;

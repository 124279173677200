import React from "react";
import log from "loglevel";
// import prefix from "loglevel-plugin-prefix";
const LoggingContext = React.createContext();
let enolog = log.noConflict();

function LoggingProvider({ children }) {
  // Get enolog level from URL parameters
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let logLevel = urlParams.get("enolog");

  // Set the log level
  React.useEffect(() => {
    if (logLevel) {
      enolog.setLevel(logLevel);
      console.log(`enolog set to ${logLevel}`);
    }
    // else {
    //   enolog.setLevel("WARN");
    //   console.log("enolog set to WARN");
    // }

    // prefix.reg(log);
    // prefix.apply(log);
  }, []);

  let log = enolog;

  // Return the Logger in LoggerContext
  return <LoggingContext.Provider value={{ log }}>{children}</LoggingContext.Provider>;
}

export { LoggingProvider, LoggingContext };

/*
Log statements available:
log.trace(msg)
log.debug(msg)
log.info(msg)
log.warn(msg)
log.error(msg)

https://stackoverflow.com/questions/7505623/colors-in-javascript-console

  class logger {
    constructor(parent) {
      // this.info = console.info.bind(window.console, "%cstuff", "color:blue");
      // this.trace = this.trace.bind(window.console);
    }

    info() {
      let args = [].slice.call(arguments);
      args.unshift("%c[timestamp]");
      args.unshift(console);
      debugger;
      return console.log.bind.apply(console.log, args); //This works but requires a double function call. ex log("stuff")()
    }

  }

*/

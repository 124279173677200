import React from "react";
import PortalRoutes from "../routes/PortalRoutes";

import { EuiFocusTrap } from "@elastic/eui";

const Portal = () => {
  return (
    <>
      <div style={{ height: "100%", backgroundColor: "#fafbfd" }}>
        <PortalRoutes />
      </div>
    </>
  );
};
export default Portal;

import React from "react";
import { UserContext } from "./context/User";

import Portal from "./layouts/PortalLayout";
import Public from "./layouts/PublicLayout";

// Script CSS
import "rsuite/dist/rsuite.min.css"; //Must be at this level to prevent it from conflicting with EUI
import "@elastic/eui/dist/eui_theme_light.css";
import "./App.css";
import Header from "./components/Header/Header";

const App = () => {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return <Public />;
  } else {
    return (
      <>
        <Header></Header>
        <Portal />
      </>
    );
  }
};

export default App;

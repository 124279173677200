import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

import { LoggingProvider } from "./context/Logger";
import { FirebaseProvider } from "./context/Firebase";
import { UserProvider } from "./context/User";

import { EuiProvider } from "@elastic/eui";
import "./index.css";

// HotJar setup
if (window.location.host === "my.enolytics.com") {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3282951, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

// Setup enolog entry
window.enolog = {};

// Setup an eno global object for troubleshooting and debugging.
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <LoggingProvider>
      <FirebaseProvider>
        <UserProvider>
          <EuiProvider>
            <App />
          </EuiProvider>
        </UserProvider>
      </FirebaseProvider>
    </LoggingProvider>
  </Router>
);

// window.eno = {};
// window.eno.mem = (id) => {
//   /**
//    * Qlik Object Memory Management
//    * active - Active Qlik Objects
//    * inactive - InActive (No memory used) Objects
//    * cache - Complete Qlik Cache with easy reference off eno.mem
//    */
//   if (id) {
//     let obj = Object.values(window.eno.qlikApp.model.enigmaModel.session.apis.entries).filter((f) => {
//       return f.api.id === id;
//     })[0];

//     return { id: obj.api.id, type: obj.api.type, isValid: obj.api.isValid, handle: obj.api.handle, viz: obj };
//   }

//   let active = Object.values(window.eno.qlikApp.model.enigmaModel.session.apis.entries)
//     .filter((f) => {
//       try {
//         return !["Doc", "Global", "Field"].includes(f.api.type) && f.api.Closed.observers.length > 0;
//       } catch (e) {
//         console.error(e);
//         console.error(f);
//         return false;
//       }
//     })
//     .map((m) => {
//       try {
//         return { id: m.api.id, type: m.api.type, isValid: m.api.isValid, handle: m.api.handle, observers: m.api.Closed.observers.length };
//       } catch (e) {
//         console.error(e);
//         console.error(m);
//         return m;
//       }
//     });

//   let inactive = Object.values(window.eno.qlikApp.model.enigmaModel.session.apis.entries)
//     .filter((f) => {
//       try {
//         return !["Doc", "Global", "Field"].includes(f.api.type) && f.api.Closed.observers.length === 0;
//       } catch (e) {
//         console.error(e);
//         console.error(f);
//         return false;
//       }
//     })
//     .map((m) => {
//       try {
//         return { id: m.api.id, type: m.api.type, isValid: m.api.isValid, handle: m.api.handle, observers: m.api.Closed.observers.length };
//       } catch (e) {
//         console.error(e);
//         console.error(m);
//         return m;
//       }
//     });

//   return {
//     active: active,
//     inactive: inactive,
//     cache: window.eno.qlikApp.model.enigmaModel.session.apis.entries,
//   };
// };

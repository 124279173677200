import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../context/Firebase";

import { LoggingContext } from "../../context/Logger";

import EnoLogo from "../../assets/Enolytics_logo-1.jpg";
import TWInputText from "../TWInputText";
import TWButton from "../TWButton";
import TWAlert from "../TWAlert";
import randomImage from "../../utils/randomImage";

const PasswordForgetPage = (props) => {
  const navigate = useNavigate();
  const { log } = React.useContext(LoggingContext);
  const { auth } = React.useContext(FirebaseContext);
  const [formState, setFormState] = React.useState({ email: "" });
  const [showAlert, setShowAlert] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [image, setImage] = useState(randomImage());

  useEffect(() => {
    setImage(randomImage());
  }, []);

  const { redirect = true } = props;
  log.debug("redirect:", redirect);

  const onChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let valErrors = {};
    if (formState.email === "") {
      valErrors = { type: "error", message: "Email is required." };
    }

    if (formState.email !== "") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const result = emailPattern.test(formState?.email);
      if (!result) valErrors = { type: "error", message: "Invalid email." };
    }

    setShowAlert(valErrors);
    if (Object.keys(valErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handlePwdRecover = async () => {
    if (validateForm()) {
      setIsloading(true);

      auth
        .doPasswordReset(formState?.email)
        .then(() => {
          setFormState({ email: "" });
          setIsloading(false);
          setShowAlert({ type: "success", message: "A Password Reset email has been sent." });
        })
        .catch((error) => {
          setIsloading(false);
          setShowAlert({ type: "error", message: "Something is wrong. Please try again or contact support." });
        });
    }
  };

  return (
    <div className="h-screen flex flex-col sm:flex-row">
      {/* Formulário de Sign In */}
      <div className="w-full lg:w-1/3 flex items-center justify-center bg-white p-8">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img alt="Your Company" src={EnoLogo} className="mx-auto h-10 w-auto" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset your MyEnolytics Password</h2>
            <p className="mt-2 text-center text-sm text-gray-600">Enter the email you log in with to reset your password.</p>
          </div>

          <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-3">
              <TWInputText placeholder="Email" id="email" name="email" type="email" required autoComplete="email" value={formState?.email} onChange={onChange} />

              <TWButton text="Reset My Password" onClick={handlePwdRecover} isLoading={isLoading} />
              <TWAlert showAlert={showAlert} fcClose={() => setShowAlert({})} />
              <div className="flex justify-between">
                <div>
                  <button onClick={() => navigate("/")} className="font-semibold text-red-600 hover:text-red-500">
                    Back to Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Imagem */}
      <div className="w-full lg:w-2/3 hidden lg:block">
        <img src={image} alt="Sample" className="h-full w-full object-cover" />
      </div>
    </div>
  );
};

export default PasswordForgetPage;

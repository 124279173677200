import React from "react";
import { UserContext } from "./User";
import { AuthContext } from "./Authorization";
import { LoggingContext } from "./Logger";

import { QlikConfig, QlikApps } from "./QlikConfig";
import StatusPage from "../components/StatusPages/EnoLoading";
import { DataContext } from "./Data";

const QlikContext = React.createContext();
const qlikUrl = `${(QlikConfig.isSecure ? "https://" : "http://") + QlikConfig.host + (QlikConfig.port ? `:${QlikConfig.port}` : "") + "/" + QlikConfig.prefix}`;

// Session Test URL
const pingConfig = {
  method: "GET",
  credentials: "include",
  url: `${qlikUrl}/qrs/about?xrfkey=somerandomstring`,
  headers: {
    "X-Qlik-XrfKey": "somerandomstring",
    "Content-Type": "application/json",
  },
};

// Load Capabilities API
const loadCapabilityApis = (log) => {
  return new Promise((resolve, reject) => {
    log.debug("loading Capabilities APIS...");
    try {
      const capabilityApisJS = document.createElement("script");
      capabilityApisJS.src = `${qlikUrl}/resources/assets/external/requirejs/require.js`;

      document.head.appendChild(capabilityApisJS);
      capabilityApisJS.loaded = new Promise((resolve) => {
        capabilityApisJS.onload = () => {
          resolve();
        };
      });

      const capabilityApisCSS = document.createElement("link");
      capabilityApisCSS.href = `${qlikUrl}/resources/autogenerated/qlik-styles.css`;
      capabilityApisCSS.type = "text/css";
      capabilityApisCSS.rel = "stylesheet";

      document.head.appendChild(capabilityApisCSS);
      capabilityApisCSS.loaded = new Promise((resolve) => {
        capabilityApisCSS.onload = () => {
          resolve();
        };
      });

      resolve(Promise.all([capabilityApisJS.loaded, capabilityApisCSS.loaded]));
    } catch (error) {
      reject(error);
    }
  });
};

// RequireJs Setup
const configureRequireJs = () => {
  //Define the Require.js config
  window.require.config({
    baseUrl: `${qlikUrl}/resources`,
    paths: {
      qlik: `${qlikUrl}/resources/js/qlik`,
    },
    config: {
      text: {
        useXhr() {
          return true;
        },
        // createXhr: function () {
        //   const Xhr = new XMLHttpRequest();
        //   Xhr.withCredentials = true;
        //   return Xhr;
        // },
      },
    },
  });
};

// Generic Error Class
class Error {
  constructor(hasError, title = "", message = "", source = "Qlik", code = null) {
    this.hasError = hasError;
    this.message = message;
    this.title = title;
    this.source = source;
    this.code = code;
  }
}

const QlikProvider = ({ children }) => {
  const { log } = React.useContext(LoggingContext);
  const { user } = React.useContext(UserContext);
  const { authData } = React.useContext(AuthContext);
  const { account, space } = React.useContext(DataContext);

  // State
  const [hasQlikSession, setHasQlikSession] = React.useState(false);
  const [capLoaded, setCapLoaded] = React.useState(false);
  const [error, setError] = React.useState(new Error(false));

  // Config
  let [qlik, setQlik] = React.useState(); //Qlik Library Object
  let [global, setGlobal] = React.useState(); //Qlik Global Object Instance
  let [appList, setAppList] = React.useState([]); //List of all apps user has access to from Qlik

  //Modified when getApp called
  // let [qlikSessions, setQlikSessions] = React.useState({}); //Collection of all open apps
  let [qlikApp, setQlikApp] = React.useState();

  // ~ DEBUG
  if (log.getLevel() < 2) {
    window.enolog.Qlik = {
      hasQlikSession: hasQlikSession,
      capLoaded: capLoaded,
      error: error,
      qlik: qlik,
      global: global,
      appList: appList,
      qlikApp: qlikApp,
    };
  }
  // ~ DEBUG

  // On hasQliKSession - Setup Qlik Session and Load Require/Capability API Libraries
  React.useEffect(() => {
    log.debug("UseEffect QlikSession: ", hasQlikSession);
    const getQlikSession = () => {
      return new Promise(async (resolve, reject) => {
        //Get Qlik Session
        let config = { ...pingConfig };
        let hasSession = false;
        let retryCount = 0;

        // Try the test url once to see if a session is established via cookie or retry to establish session
        while (!hasSession) {
          try {
            let response = await fetch(config.url, config);
            if (!response.ok) throw "Fetch Error";
            hasSession = true;
            resolve();
          } catch (error) {
            log.debug(`Attempt ${retryCount + 1} to Qlik Server`);

            //Add JWT token to the config
            const jwtToken = await user.getQlikToken();
            // log.trace("Token:", jwtToken.jwt);
            // debugger;
            config.headers.Authorization = `Bearer ${jwtToken.jwt}`;
          }

          if (retryCount === 5) {
            reject();
            break;
          }
          retryCount++;
        }
      });
    };

    //getQlikSession if no session established
    if (user && !hasQlikSession) {
      getQlikSession()
        .then(() => {
          setHasQlikSession(true);
        })
        .catch(() => {
          setError(new Error(true, "Maximum Retry Count Reached", "We are having difficulty connecting.  Please try again", "Qlik", "connection"));
        });
    } else if (hasQlikSession && !capLoaded) {
      loadCapabilityApis(log)
        .then(() => {
          log.debug("CapabilityAPILoaded...");
          setCapLoaded(true);
          configureRequireJs();
        })
        .catch((e) => {
          log.error(e);
          setError(new Error(true, "Error Loading Require.js", "We are having difficulty connecting.  Please try again", "Qlik", "connection"));
        });
    }
  }, [hasQlikSession]);

  // Load Qlik Global Objects from Require JS
  React.useEffect(() => {
    if (hasQlikSession && capLoaded) {
      try {
        window.require(
          ["js/qlik"],
          (q) => {
            //Handle Qlik Errors via listener
            q.setOnError(async (error) => {
              // TODO:error handling
              log.error("qlik() error", error);
              // if (error && error.code === 16) {
              //   setError(new Error(true, "Connection Error", error.message, "Qlik", "session"));
              // }
            });

            // Get Qlik App List
            let g = q.getGlobal({ ...QlikConfig });
            g.getAppList(
              (list) => {
                // log.debug("Setting AppList", list);
                // Set state for AppList
                setAppList(list);
                setQlik(q);
                setGlobal(g);
              },
              { ...QlikConfig }
            );

            // qlikglobals.resize = () => {
            // q.resize();
            // };
          },
          (err) => {
            log.error("Error from window.require[js/qlik]", err);
            window.location.reload();
          }
        );
      } catch (error) {
        log.error("Error with RequireJS Setup for Qlik in useEffect");
        console.error(error);
      }
    }
  }, [hasQlikSession, capLoaded]);

  React.useEffect(() => {
    (async () => {
      if (account && space && appList.length > 0) {
        let app = await getApp(account, space);
        setQlikApp(app);
      }
    })();
  }, [account, space, appList]);

  const getApp = (acctNo, appName) => {
    //TODO: Update to only keep two to three apps/accounts at a time... closing oldest as new ones added.
    log.debug("Qlik Context getApp():", acctNo, appName);
    // Get legacy config default App ID.
    let id = "None Found";

    log.debug("Getting QlikApp from Qlik...");

    // // If the qlik App session for this account is open and available then return it.
    // if (Object.hasOwn(qlikSessions, acctNo) && Object.hasOwn(qlikSessions[acctNo], appName)) {
    //   //TODO: How to I also check to see if it is open/closed?
    //   return qlikSessions[acctNo][appName];
    // }

    return new Promise((resolve, reject) => {
      // Open Qlik App
      log.debug("Getting Qlik App", acctNo, appName);

      // Validate Account Number
      if (!acctNo || acctNo === "" || !Object.keys(authData.accounts).includes(acctNo)) {
        setError(new Error(true, "Invalid Account", "Your Account setup is not completed.  Please contact support.  Thank you!", "Qlik", "authorization"));
      } //TODO: Does this make sense to keep anymore?  It can't really get here without an accountNo.

      // Get available list of apps and filter them according to criteria
      let list = appList.filter((qApp) => {
        return (
          qApp?.qMeta?.stream?.name.includes(acctNo) && //Stream contains the Account ID
          qApp?.qMeta?.published === true && //Has to be published
          qApp?.qDocName?.includes(acctNo) && // DocName contains Account ID
          qApp?.qDocName.includes(QlikApps["docNames"][appName]) && //DocName contains designated name
          !qApp?.qDocName?.includes("QVD Builder") // No QVD Builders
        );
      });

      // Get first app from the list or fallback to default.
      id = list[0]?.qDocId || null;

      if (!id) {
        setError(new Error(true, "No Application Found", "No Application was found for this Account.  Please contact support.", "Qlik", "connection"));
        reject("No App Found!!");
      }

      log.debug("Found Qlik App:", id);

      // Open the App.
      const app = qlik.openApp(id, {
        ...QlikConfig,
      });

      // Apply the enolytics theme globally. (trick is to get the name correct)
      qlik.theme.apply("enolytics-theme").then((r) => log.debug("Theme Applied:", r));

      // Qlik Error Handling
      app.on("error", async (e) => {
        log.error("Qlik App Engine Error", e);
        if (e.method === "OnSessionTimedOut") {
          setError(new Error(true, "Your Session has timed out", "It looks like you were taking a break. Are you ready to continue your session?", "Qlik", "session"));
          // setQlikSessions({
          //   ...qlikSessions,
          //   [acctNo]: {
          //     [appName]: null,
          //   },
          // }); //setting session to null if timeout
          setQlikApp(null); // setting session to null if timeout
        } else if (e.message === "ProxyError.OnSessionTimedOut") {
          //|| e.message === "ErrorDialog.NoConnectionCreated"
          setError(new Error(true, "Your Session has timed out", "It looks like you were taking a break. Are you ready to continue your session?", "Qlik", "session"));
          // setQlikSessions({
          //   ...qlikSessions,
          //   [acctNo]: {
          //     [appName]: null,
          //   },
          // }); // setting session to null if timeout
          setQlikApp(null); // setting session to null if timeout
        } else if (e.message === "Forbidden") {
          setError(new Error(true, "Forbidden", "You do not have access to this Application.  Please contact support.  Thank you!", "Qlik", "authorization"));
        }
      });
      // TODO: If enable this closes after the getAppList call.
      // Removed to keep the React app from displaying session timout.
      // app.on("closed", (e) => {
      //   log.debug("App Closed");
      //   log.debug("Closed Message", e);
      //   setError("Your Session has timed out");
      // });
      app.on("warning", (e) => {
        log.warn("App Warning");
        log.warn("Warning Message", e);
        // setError("Your Session has timed out");
      });

      // for (let index = 0; index < Object.keys(qlikSessions).length; index++) {
      //   const sessionAcctId = Object.keys(qlikSessions)[index];
      //   sessionAcctId["dtc"] && sessionAcctId["dtc"].close()
      //   sessionAcctId["depl"] && sessionAcctId["depl"].close()
      // }
      qlikApp && qlikApp.close(); //Close Current Session

      // setQlikSessions({
      //   // ...qlikSessions,
      //   [acctNo]: {
      //     [appName]: app,
      //   },
      // });

      // setQlikApp(app)

      resolve(app);
    });
  };

  const logoutQlik = () => {
    let qpsUrl = `${(QlikConfig.isSecure ? "https://" : "http://") + QlikConfig.host + (QlikConfig.port ? `:${QlikConfig.port}` : "") + "/" + QlikConfig.prefix + "/qps"}`;
    const logoutConfig = {
      method: "DELETE",
      credentials: "include",
      url: `${qpsUrl}/user?xrfkey=somerandomstring`,
      headers: {
        "X-Qlik-XrfKey": "somerandomstring",
        "Content-Type": "application/json",
      },
    };

    // Call Qlik to Logout
    fetch(logoutConfig.url, logoutConfig)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        log.error(`Failed attempting to Logout of Qlik Server`);
        log.error(error);
      });
  };

  // Loading Page
  if (!qlik && !global) {
    return <StatusPage></StatusPage>; //<div>Loading Q ...</div>;
  }

  // Return Qlik Context
  return (
    <>
      <QlikContext.Provider value={{ qlikUrl, qlikApp, qlik, global, appList, logoutQlik, error, config: { QlikConfig, QlikApps }, getApp }}>{children}</QlikContext.Provider>
    </>
  );
};

export { QlikProvider, QlikContext };

//TODO: Depletion MVP Items
/**
 * X 1. Return Loading Page and not a null value. Line 299
 * X 2. Add Function to get Apps when called or initialize from Qlik add to Context for Downstream
 * X 3. Allow for multiple open Qlik Apps.... store in context session array? populate on calling above getApp function or return the current app in session.
 * X 4. Remove/Replace Axios
 * 5. To move the QlikConfig to FS. I need to move the functions inside the React Function and add a DataContext reference to get Qli URLS and handle errors/blank
 * 6. Move the error state handler to the Data Object and add DataContext reference to it. - Maybe?
 */

//~Notes: line 180 - bug resolution for closing qlik socket: https://community.qlik.com/t5/Official-Support-Articles/Qlik-Sense-ErrorCode-16-in-mashups/ta-p/1712889

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context("../assets/img", false, /\.(png|jpe?g|svg)$/));

const randomImage = () => {
  return images[Math.floor(Math.random() * images.length)];
};

export default randomImage;

const QlikConfig = {
  prefix: "myeno",
  host: "data.enolytics.com",
  port: 443,
  isSecure: true,
};

const QlikApps = {
  haasbros: "3173a0ba-9017-4f63-8044-18b3b04c2ca9",
  haasbrosLegacy: "605da016-3bf2-4376-a9e2-f378282839de",
  depletion: "39022d88-689a-4cd1-ae22-65ab5452bdef",
  docNames: {
    dtc: "DTC",
    depletion: "Depletion",
    haasbros: "Haas",
    depl: "DEPL",
  },
};

export { QlikConfig, QlikApps };

export default [
  {
    links: [
      {
        label: "KPI Dashboard",
        navlink: "dtc/kpi_dashboard",
      },
    ],
    permission: "DTC_NAV_KPI_DASHBOARD",
    title: "Executive Overview",
  },
  {
    links: [
      {
        label: "General",
        navlink: "dtc/org",
      },
      {
        label: "Tasting Room",
        navlink: "dtc/org/tasting-room",
      },
      {
        label: "Club Channel",
        navlink: "dtc/org/club",
      },
      {
        label: "Contact Crossover",
        navlink: "dtc/org/contact-xover",
      },
    ],
    permission: "DTC_NAV_ORGANIZATION",
    title: "Organization Overview",
  },
  {
    links: [
      {
        label: "Highlights",
        navlink: "dtc/orders",
      },
      {
        label: "How Much Did We Sell?",
        navlink: "dtc/orders/how-much",
      },
      {
        label: "What Did We Sell?",
        navlink: "dtc/orders/what",
      },
      {
        label: "What Was Sold Together?",
        navlink: "dtc/orders/basket-analysis",
      },
      {
        label: "Who Did We Sell To?",
        navlink: "dtc/orders/who",
      },
      {
        label: "Where Did We Sell?",
        navlink: "dtc/orders/where",
      },
      {
        label: "When Did We Sell?",
        navlink: "dtc/orders/when",
      },
      {
        label: "Trends",
        navlink: "dtc/orders/trends",
      },
      {
        label: "Other Interesting Things",
        navlink: "dtc/orders/other-interesting",
      },
    ],
    permission: "DTC_NAV_SALES",
    title: "Sales",
  },
  {
    links: [
      {
        label: "Highlights",
        navlink: "dtc/tasting-room/highlights",
      },
      {
        label: "What did we Sell?",
        navlink: "dtc/tasting-room/what",
      },
      {
        label: "Who Sold it?",
        navlink: "dtc/tasting-room/who-sold",
      },
      {
        label: "Who Bought it?",
        navlink: "dtc/tasting-room/who-bought",
      },
      {
        label: "When did we Sell it?",
        navlink: "dtc/tasting-room/when",
      },
      {
        label: "Tasting Details",
        navlink: "dtc/tasting-room/details",
      },
      {
        label: "Glass Pours",
        navlink: "dtc/tasting-room/glass-pours",
      },
      {
        label: "Orders Overview",
        navlink: "dtc/tasting-room/orders-overview",
      },
      {
        label: "Trends",
        navlink: "dtc/tasting-room/trends",
      },
      {
        label: "Lifetime Performance",
        navlink: "dtc/tasting-room/lifetime",
      },
    ],
    permission: "DTC_NAV_TASTING_ROOM",
    title: "Tasting Room",
  },
  {
    links: [
      {
        label: "Highlights",
        navlink: "dtc/wineclub",
      },
      {
        label: "How Many Members Do We Have?",
        navlink: "dtc/wineclub/how-many",
      },
      {
        label: "Who are our Club Members?",
        navlink: "dtc/wineclub/whos-in",
      },
      {
        label: "What is my Membership Churn?",
        navlink: "dtc/wineclub/what",
      },
      {
        label: "Why are Members Leaving?",
        navlink: "dtc/wineclub/why",
      },
      {
        label: "Who is at Risk of Leaving?",
        navlink: "dtc/wineclub/at-risk",
      },
      {
        label: "Who are my Best Customers?",
        navlink: "dtc/wineclub/best-cust",
      },
      {
        label: "What did we Sell Through the Wine Club?",
        navlink: "dtc/wineclub/sell-through",
      },
      {
        label: "Who Changed Status?",
        navlink: "dtc/wineclub/changed-status",
      },
      {
        label: "Trends",
        navlink: "dtc/wineclub/trends",
      },
      {
        label: "Other Interesting Things",
        navlink: "dtc/wineclub/other-interesting",
      },
    ],
    permission: "DTC_NAV_CLUB",
    title: "Wine Club",
  },
  {
    links: [
      {
        label: "What to Know about my Contacts?",
        navlink: "dtc/contacts/what-to-know",
      },
      {
        label: "Who was Added?",
        navlink: "dtc/contacts/who-added",
      },
      {
        label: "Who are Duplicate Contacts?",
        navlink: "dtc/contacts/who-duplicated",
      },
      {
        label: "Contact Types",
        navlink: "dtc/contacts/contact-types",
      },
      {
        label: "Who are my Contacts?",
        navlink: "dtc/contacts/who-contacts",
      },
    ],
    permission: "DTC_NAV_CONTACTS",
    title: "Contacts",
  },
  {
    links: [
      {
        label: "Varietal Performance",
        navlink: "dtc/products/varietal-perf",
      },
      {
        label: "Wine Performance",
        navlink: "dtc/products/wine-perf",
      },
      {
        label: "Wine Product Performance",
        navlink: "dtc/products/wine-prod-perf",
      },
      {
        label: "Bundle Performance",
        navlink: "dtc/products/bundle-perf",
      },
      {
        label: "Non Wine Product Performance",
        navlink: "dtc/products/non-wine-prod-perf",
      },
    ],
    permission: "DTC_NAV_PRODUCTS",
    title: "Products",
  },
  {
    links: [
      {
        label: "Customers",
        navlink: "dtc/data-quality/customers",
      },
      {
        label: "Prospects",
        navlink: "dtc/data-quality/prospects",
      },
      {
        label: "Product",
        navlink: "dtc/data-quality/product",
      },
      {
        label: "Orders that Need Review",
        navlink: "dtc/data-quality/review",
      },
    ],
    permission: "DTC_NAV_DATA_QUALITY",
    title: "Data Quality",
  },
  {
    links: [
      {
        label: "Order Sales Summary",
        navlink: "dtc/advanced-reporting/order-sales-summary",
      },
      {
        label: "Orders Performance Indicators",
        navlink: "dtc/advanced-reporting/orders-perf-ind",
      },
      {
        label: "Tasting Room",
        navlink: "dtc/advanced-reporting/tastingroom",
      },
      {
        label: "Wine Club Daily Summary",
        navlink: "dtc/advanced-reporting/wine-club-summary",
      },
      {
        label: "Wine Club Monthly Summary",
        navlink: "dtc/advanced-reporting/club-mnth-summary",
      },
      {
        label: "Wine Club Performance Indicators",
        navlink: "dtc/advanced-reporting/club-perf-ind",
      },
      {
        label: "Contact Data",
        navlink: "dtc/advanced-reporting/contact-data",
      },
      {
        label: "Inventory",
        navlink: "dtc/advanced-reporting/inventory",
      },
      {
        label: "Coupons",
        navlink: "dtc/advanced-reporting/coupons",
      },
      {
        label: "Gift Cards",
        navlink: "dtc/advanced-reporting/giftcards",
      },
      {
        label: "Tender",
        navlink: "dtc/advanced-reporting/tender",
      },
      {
        label: "Budget",
        navlink: "dtc/advanced-reporting/budget",
      },
      {
        label: "Custom Reports",
        navlink: "dtc/advanced-reporting/custom-reports",
      },
    ],
    permission: "DTC_NAV_ADV_REPORTS",
    title: "Advanced Reporting",
  },
  {
    links: [
      {
        label: "Daily Reports",
        navlink: "dtc/emailed-reports/daily-reports",
      },
      {
        label: "Weekly Reports",
        navlink: "dtc/emailed-reports/weekly-reports",
      },
    ],
    permission: "DTC_NAV_EMAILED_REPORTS",
    title: "Emailed Reports",
  },
  {
    links: [
      {
        label: "By Varietal",
        navlink: "dtc/adv-seg/varietal",
      },
      {
        label: "By Wine",
        navlink: "dtc/adv-seg/wine",
      },
      {
        label: "By Product",
        navlink: "dtc/adv-seg/product",
      },
      {
        label: "By Channel",
        navlink: "dtc/adv-seg/channel",
      },
    ],
    permission: "DTC_NAV_ADV_SEGMENTATION",
    title: "Advanced Segmentation",
  },
  {
    links: [
      {
        label: "General",
        navlink: "dtc/budget/general",
      },
      {
        label: "Tasting Room",
        navlink: "dtc/budget/tasting-room",
      },
      {
        label: "Club",
        navlink: "dtc/budget/club",
      },
      {
        label: "ECommerce",
        navlink: "dtc/budget/ecommerce",
      },
      {
        label: "Winery Summary",
        navlink: "dtc/budget/summary",
      },
      {
        label: "Sales Associate",
        navlink: "dtc/budget/associate",
      },
      {
        label: "Sales Associate Summary",
        navlink: "dtc/budget/associate-summary",
      },
    ],
    permission: "DTC_NAV_BUDGET",
    title: "Budget",
  },
  {
    links: [
      {
        label: "Custom Dashboard",
        navlink: "dtc/custom",
      },
    ],
    permission: "DTC_NAV_CUSTOM_DASHBOARD",
    title: "Custom Dashboard",
  },
  {
    links: [
      {
        label: "Your Mapping Data",
        navlink: "dtc/mapping",
      },
      {
        label: "Configuration",
        navlink: "dtc/mapping/config",
      },
    ],
    permission: "DTC_NAV_MAPPING",
    title: "Mapping",
  },
];

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LoggingContext } from "../../context/Logger";
import { DataContext } from "../../context/Data";
import { AuthContext } from "../../context/Authorization";

import DeplConfig from "../../views/Depletion/DepletionRoutesNav";
import DTCConfig from "../../views/DTC/DTCRoutesNav";

import _ from "lodash";
import {
  EuiFlexItem,
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPinnableListGroup,
  EuiShowFor,
  EuiHorizontalRule,
  EuiListGroupItem,
  EuiImage,
  EuiFlexGroup,
} from "@elastic/eui";

import "./SideNav.css";

const SideNav = () => {
  const { log } = React.useContext(LoggingContext);
  const { AllowedTo } = React.useContext(AuthContext);
  const { accountLogo, account, space } = React.useContext(DataContext);

  log.debug("SideNav Render");
  const navigate = useNavigate();
  const location = useLocation();

  let spaceId = space;

  //State Variables
  const [navIsOpen, setNavIsOpen] = React.useState(true);
  const [navIsDocked, setNavIsDocked] = React.useState(true);
  const [navConfig, setNavConfig] = React.useState([]);

  // On Location Change - Set NavConfig
  React.useEffect(() => {
    console.log("SideNav Location Changed", space);
    // ~ Add Navigation Configs from each View DTC/DEPL
    let config = { depl: DeplConfig, dtc: DTCConfig };

    setNavConfig(config[space] || []); //Empty Array when no NavConfig (ex admin pages)
  }, [space]);

  const TopLinks = [
    // {
    //   label: "Home",
    //   iconType: "home",
    //   isActive: true,
    //   "aria-current": true,
    //   onClick: () => history.push(currPage),
    //   pinnable: false,
    // },
  ];

  /**
   * Accordion toggling
   */
  const [openGroups, setOpenGroups] = React.useState(JSON.parse(String(localStorage.getItem("openNavGroups_" + spaceId))) || []);

  // Save which groups are open and which are not with state and local store
  const toggleAccordion = (isOpen, title) => {
    if (!title) return;
    const itExists = openGroups.includes(title);
    if (isOpen) {
      if (itExists) return;
      openGroups.push(title);
    } else {
      const index = openGroups.indexOf(title);
      if (index > -1) {
        openGroups.splice(index, 1);
      }
    }
    setOpenGroups([...openGroups]);
    localStorage.setItem("openNavGroups_" + spaceId, JSON.stringify(openGroups));
  };

  /**
   * Pinning
   */
  const [pinnedItems, setPinnedItems] = React.useState(JSON.parse(String(localStorage.getItem("pinnedItems_" + spaceId))) || []); //Todo: Pull this from user profile.

  const addPin = (item) => {
    if (!item || _.find(pinnedItems, { label: item.label })) {
      return;
    }
    item.pinned = true;
    const newPinnedItems = pinnedItems ? pinnedItems.concat(item) : [item];
    setPinnedItems(newPinnedItems);
    localStorage.setItem("pinnedItems_" + spaceId, JSON.stringify(newPinnedItems));
  };

  const removePin = (item) => {
    const pinIndex = _.findIndex(pinnedItems, { label: item.label });
    if (pinIndex > -1) {
      item.pinned = false;
      const newPinnedItems = pinnedItems;
      newPinnedItems.splice(pinIndex, 1);
      setPinnedItems([...newPinnedItems]);
      localStorage.setItem("pinnedItems_" + spaceId, JSON.stringify(newPinnedItems));
    }
  };

  const alterLinksWithCurrentState = (links, showPinned = false) => {
    return links.map((link) => {
      const { pinned, navlink, isActive, ...rest } = link;
      return {
        pinned: showPinned ? pinned : false,
        onClick: () => navigate(navlink),
        navlink: navlink, //Required to persist navlink in local storage when pinned.
        isActive: navlink === location.pathname.substring(1) ? true : false, //Removed leading "/"
        ...rest,
      };
    });
  };

  function addLinkNameToPinTitle(listItem) {
    return `Pin ${listItem.label} to top`;
  }

  function addLinkNameToUnpinTitle(listItem) {
    return `Unpin ${listItem.label}`;
  }

  // Map and build Nav Sections
  // TODO: Add greyed out Nav Sections when AllowedTo = no.
  const getSections = () => {
    return navConfig.map((config, index) => {
      return (
        <AllowedTo key={index} perform={config.permission} data={account}>
          <EuiCollapsibleNavGroup
            title={config.title}
            iconType={config.iconType}
            isCollapsible={true}
            initialIsOpen={openGroups.includes(config.title)}
            onToggle={(isOpen) => toggleAccordion(isOpen, config.title)}
            key={index}
            titleSize="xxs"
          >
            <EuiPinnableListGroup
              aria-label={config.title} // A11y : EuiCollapsibleNavGroup can't correctly pass the `title` as the `aria-label` to the right HTML element, so it must be added manually
              listItems={alterLinksWithCurrentState(config.links)}
              pinTitle={addLinkNameToPinTitle}
              onPinClick={addPin}
              maxWidth="none"
              // color="subdued"
              gutterSize="none"
              size="s"
            />
          </EuiCollapsibleNavGroup>
        </AllowedTo>
      );
    });
  };

  // Display Nav if available for this route type
  if (navConfig.length > 0) {
    return (
      <EuiCollapsibleNav
        id="sideNav"
        aria-label="SideBar Navigation"
        isOpen={navIsOpen}
        isDocked={navIsDocked}
        button={
          <EuiHeaderSectionItemButton aria-label="Toggle main navigation" onClick={() => setNavIsOpen(!navIsOpen)}>
            <EuiIcon type={"menu"} size="m" aria-hidden="true" />
          </EuiHeaderSectionItemButton>
        }
        onClose={() => {
          setNavIsOpen(false);
          setNavIsDocked(false);
        }}
      >
        {/* Shaded pinned section */}
        <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
          {/*//~ LOGO */}
          <EuiCollapsibleNavGroup className="navLogo">
            {/* FlexGroup to center Image Logo */}
            <EuiFlexGroup justifyContent="spaceAround">
              <EuiFlexItem grow={false}>
                <EuiImage url={accountLogo} size="original" alt="logo" style={{ height: "auto", maxHeight: "75px", width: "auto", maxWidth: "100%" }}></EuiImage>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCollapsibleNavGroup>
          {/* //~ Pinned Items */}
          <EuiCollapsibleNavGroup className="eui-yScroll" style={{ maxHeight: "40vh" }}>
            <EuiPinnableListGroup
              aria-label="Pinned links" // A11y : Since this group doesn't have a visible `title` it should be provided an accessible description
              listItems={alterLinksWithCurrentState(TopLinks).concat(alterLinksWithCurrentState(pinnedItems, true))}
              unpinTitle={addLinkNameToUnpinTitle}
              onPinClick={removePin}
              maxWidth="none"
              color="text"
              gutterSize="none"
              size="s"
            />
          </EuiCollapsibleNavGroup>
        </EuiFlexItem>
        <EuiHorizontalRule margin="none" />
        {/* BOTTOM */}
        <EuiFlexItem className="eui-yScroll">
          {/* Dynamic Nav sections */}
          {getSections()}
        </EuiFlexItem>
        <EuiHorizontalRule margin="none" />
        {/* //~ Docking Button */}
        <EuiFlexItem grow={false}>
          {/* Docking button only for larger screens that can support it*/}
          <EuiShowFor sizes={["l", "xl"]}>
            <EuiCollapsibleNavGroup>
              <EuiListGroupItem
                size="xs"
                color="subdued"
                label={`${navIsDocked ? "Undock" : "Dock"} navigation`}
                onClick={() => {
                  setNavIsDocked(!navIsDocked);
                  localStorage.setItem("navIsDocked", JSON.stringify(!navIsDocked));
                }}
                iconType={navIsDocked ? "lock" : "lockOpen"}
              />
            </EuiCollapsibleNavGroup>
          </EuiShowFor>
        </EuiFlexItem>
      </EuiCollapsibleNav>
    );
  } else {
    return <></>;
  }
};

export default React.memo(SideNav);

import React from "react";
import { useNavigate } from "react-router-dom";
import SideNav from "../../components/SideNav/SideNav";

// Context
import { LoggingContext } from "../../context/Logger";
import { UserContext } from "../../context/User";
import { DataContext } from "../../context/Data";
import { QlikContext } from "../../context/Qlik";
import { AuthContext } from "../../context/Authorization";

import HeadwayWidget from "../HeadwayWidget/HeadwayWidget";
import Spaces from "../Spaces/Spaces";

import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiHeaderLogo,
  EuiPopover,
  EuiAvatar,
  EuiText,
  EuiSpacer,
  EuiPopoverFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
} from "@elastic/eui";

import "./Header.css";
import enoLogo from "../../assets/EnolyticsLarge_WhiteLetters_Transparent@2x.png";

// Header component
const Header = (props) => {
  const { log } = React.useContext(LoggingContext);
  log.debug("Header Component");

  const navigate = useNavigate();

  const { user } = React.useContext(UserContext);
  const { version } = React.useContext(DataContext);
  const { useAuth, authData } = React.useContext(AuthContext);
  const { logoutQlik, config } = React.useContext(QlikContext);
  const { userHasPermission } = useAuth();

  /**
   * Admin Menu
   */
  const adminMenu = () => {
    if (!userHasPermission(["ROUTE_ADMIN_VIEW"])) return null;

    return (
      <EuiPopoverFooter>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                setIsUserMenuVisible(false);
                navigate("/admin");
              }}
              iconType={"indexSettings"}
              iconSize="l"
              color="danger"
            >
              <EuiText>Admin Panel</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                window.open(`https://${config.QlikConfig.host}/${config.QlikConfig.prefix}/hub`, "_blank");
              }}
              iconType={"visLine"}
              iconSize="l"
              color="danger"
            >
              <EuiText>GoTo Hub</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                window.open(`https://${config.QlikConfig.host}/${config.QlikConfig.prefix}/qmc`, "_blank");
              }}
              iconType={"wrench"}
              iconSize="l"
              color="danger"
            >
              <EuiText>GoTo QMC</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPopoverFooter>
    );
  };

  /**
   * User Menu
   */
  const [isUserMenuVisible, setIsUserMenuVisible] = React.useState(false);
  const userMenu = (
    <EuiPopover
      id="guideHeaderUserMenuExample"
      ownFocus
      button={
        <EuiHeaderSectionItemButton
          aria-controls="guideHeaderUserMenuExample"
          aria-expanded={isUserMenuVisible}
          aria-haspopup="true"
          aria-label="User menu"
          onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
        >
          <EuiAvatar
            name={user.profile.name}
            size="s"
            // imageUrl="https://source.unsplash.com/64x64/?cat"
            color="#DCDCDC"
          />
        </EuiHeaderSectionItemButton>
      }
      isOpen={isUserMenuVisible}
      anchorPosition="downRight"
      repositionOnScroll
      closePopover={() => setIsUserMenuVisible(false)}
    >
      <div style={{ width: 290 }}>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem style={{ maxWidth: "60px" }}>
            <EuiAvatar
              name={user.profile.name}
              size="xl"
              color="#DCDCDC"
              // imageUrl="https://source.unsplash.com/64x64/?cat"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="m">
              <h3>{user.profile.name}</h3>
            </EuiText>
            <EuiText size="s" color="subdued">
              {user.profile.email}
            </EuiText>
            <EuiSpacer size="l" />
            <EuiText size="xs" color="subdued">
              Enolytics v{version}
            </EuiText>
            {/* <Link size="xs" color="subdued" to="/profile">
              {"Profile & Preferences"}
            </Link> */}
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <EuiPopoverFooter>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                window.open("https://www.enolytics.com/schedule-a-workshop", "_blank");
              }}
              iconType={"calendar"}
              iconSize="l"
            >
              <EuiText>Schedule Workshop</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                window.open("https://support.enolytics.com/support/login", "_blank");
              }}
              iconType={"help"}
              iconSize="l"
            >
              <EuiText>Support Portal</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              onClick={() => {
                logoutQlik();
                user.auth.auth.signOut();
              }}
              iconType={"exit"}
              iconSize="l"
            >
              <EuiText>Sign out</EuiText>
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPopoverFooter>
      {adminMenu()}
    </EuiPopover>
  );

  return (
    <>
      <EuiHeader position="fixed" theme="dark" style={{}}>
        <EuiHeaderSection grow={false}>
          <EuiHeaderSectionItem>
            <SideNav />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem border="right">
            <EuiHeaderLogo
              iconType={() => {
                return <EuiIcon type={enoLogo} size="l" className="top-logo"></EuiIcon>;
              }}
              onClick={() => navigate("/" + user.profile.protected.default_route)}
              aria-label="Goes to home"
              style={{ padding: "0 5px 0 10px" }}
            />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem border="left">
            <HeadwayWidget />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem border="right">
            <Spaces />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem>{userMenu}</EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </>
  );
};

export default React.memo(Header);

//TODO: Remaining Depletion Todos
/**
 * X 1. Add Spaces selector to change current route and update Data Session for Account and DTC/Depletion...
 * 3. Add Impersonation switcher to Header
 * 4. Add Qlik Details for current app to header with menu to connect to Qlik
 * X 5. Change Logo click to go do default route... (remove dtc)
 * X 6. Add Enolytics Administrator links.. to user profile in header.
 */

export default [
  {
    links: [
      {
        label: "KPI Dashboard",
        navlink: "depl/kpi-dashboard",
      },
    ],
    permission: "DEPL_NAV_KPI_DASHBOARD",
    title: "Executive Overview",
  },
  {
    links: [
      {
        label: "How Much Did We Sell?",
        navlink: "depl/performance/summary",
      },
      {
        label: "How Many Cases Sold Each Month?",
        navlink: "depl/performance/cases",
      },
      {
        label: "Cases and PODS per Account",
        navlink: "depl/performance/accounts",
      },
      {
        label: "Where Did We Sell?",
        navlink: "depl/performance/where",
      },
      {
        label: "Trends",
        navlink: "depl/performance/trends",
      },
      {
        label: "Other Interesting Things",
        navlink: "depl/performance/other",
      },
    ],
    permission: "DEPL_NAV_PERFORMANCE",
    title: "Performance",
  },
  {
    links: [
      {
        label: "Account Status Overview",
        navlink: "depl/accounts/overview",
      },
      {
        label: "What Changed in My Accounts?",
        navlink: "depl/accounts/changes",
      },
    ],
    permission: "DEPL_NAV_ACCOUNTS",
    title: "Accounts",
  },
  {
    links: [
      {
        label: "Where to Expand Off-Premise",
        navlink: "depl/suggestions/off-prem",
      },
      {
        label: "Where to Expand On-Premise",
        navlink: "depl/suggestions/on-prem",
      },
      {
        label: "Where to Expand in Chains",
        navlink: "depl/suggestions/chains",
      },
    ],
    permission: "DEPL_NAV_SUGGESTIONS",
    title: "Suggested Expansions",
  },
  {
    links: [
      {
        label: "What is my Depletion Rate?",
        navlink: "depl/inventory/depletion",
      },
      {
        label: "Quantity and Location?",
        navlink: "depl/inventory/where",
      },
      {
        label: "Other Interesting Things",
        navlink: "depl/inventory/other",
      },
    ],
    permission: "DEPL_NAV_INVENTORY",
    title: "Inventory",
  },
  {
    links: [
      {
        label: "Quick Reports",
        navlink: "depl/reporting/quick",
      },
      {
        label: "Advanced Reporting",
        navlink: "depl/reporting/advanced",
      },
      // {
      //   label: "Most Popular",
      //   navlink: "depl/reporting/most-popular",
      // },
    ],
    permission: "DEPL_NAV_REPORTING",
    title: "Reporting",
  },
  //   {
  //     links: [
  //       {
  //         label: "By Wine",
  //         navlink: "depl/segmentation/item",
  //       },
  //     ],
  //     permission: "DEPL_NAV_SEGMENTATION",
  //     title: "Advanced Segmentation",
  //   },
  {
    links: [
      {
        label: "Comparison to Avg Pricing",
        navlink: "depl/pricing/comparison",
      },
      {
        label: "Sales Price Distribution",
        navlink: "depl/pricing/distribution",
      },
      {
        label: "Price Range by Item",
        navlink: "depl/pricing/range",
      },
    ],
    permission: "DEPL_NAV_PRICING",
    title: "Pricing",
  },
  {
    links: [
      {
        label: "Combined Cases by Month",
        navlink: "depl/combined/cases",
      },
      {
        label: "Items by Channel",
        navlink: "depl/combined/channels",
      },
    ],
    permission: "DEPL_NAV_COMBO",
    title: "Combined DTC/Wholesale",
  },
  {
    links: [
      {
        label: "Custom Dashboard",
        navlink: "depl/custom",
      },
    ],
    permission: "DEPL_NAV_CUSTOM_DASHBOARD",
    title: "Custom Dashboard",
  },
];

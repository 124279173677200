import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { FirebaseContext } from "../../context/Firebase";

export default function FinishSignIn() {
  const { auth } = useContext(FirebaseContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("@eno:email");

      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      auth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem("@eno:email");
          navigate("/");
        })
        .catch((e) => {
          navigate("/");
        });
    }
  }, []);
  return <div />;
}

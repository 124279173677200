import React, { useMemo } from "react";
import "./EnoLoading.css";

import loadingIcon from "./assets/icon.png";
import chartIcon from "./assets/chart.png";
import barchartIcon from "./assets/barchart.png";

const images = [chartIcon, barchartIcon];
const EnoLoading = () => {
  const [message, setMessage] = React.useState(getRandomMessage());

  const floatingImages = useMemo(() => {
    return Array.from({ length: 20 }, () => ({
      src: images[Math.floor(Math.random() * images.length)],
      style: {
        left: `${Math.random() * 100}%`,
        animationDuration: `${10 + Math.random() * 20}s`,
        animationDelay: `${Math.random() * 5}s`,
        width: `${30 + Math.random() * 50}px`,
        opacity: Math.random() * 0.5,
      },
    }));
  });

  const getRandomSize = () => {
    return Math.floor(Math.random() * 100) + 20;
  };

  return (
    <>
      <div className="loading-screen">
        <div className="icon-container">
          <img src={loadingIcon} alt="Loading Icon" className="icon" style={{ maxWidth: "auto", height: "100px" }} />
        </div>
        <div className="loading-text">{message}</div>
      </div>
      <div className="fixed inset-0 bg-transparent overflow-hidden z-0">
        {floatingImages.map((image, index) => {
          const size = getRandomSize();
          return (
            <img
              key={index}
              className="newBubble"
              src={image.src}
              alt={`Floating ${index + 1}`}
              style={{
                left: `${Math.random() * 100}%`,
                animationDuration: `${3 + Math.random() * 10}s`,
                animationDelay: `${Math.random() * 5}s`,
                width: `${size}px`,
                height: `${size}px`,
                opacity: Math.random() * 0.2,
                objectFit: "contain",
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default EnoLoading;

function getRandomMessage() {
  var texts = [
    "Sit back, relax, and watch as your data dances its way into becoming a wellspring of valuable knowledge....",
    "Hold on tight, we're mining your data for gold!",
    "Just a moment, we're brewing your data into a fine cup of insights.",
    "While you wait, our data wizards are conjuring up valuable insights.",
    "Patience is key! We're unlocking the secrets of your data.",
    "Sit back, relax, and let us work our magic on your data.",
    "While the hamsters in our data wheels spin, your insights are coming together.",
    "Don't worry, we're sifting through the digital haystack to find your golden insights.",
    "In the meantime, our algorithms are giving your data a high-five for being so interesting.",
    "Hang in there, we're transforming your data into nuggets of wisdom.",
    "Your data is on a rollercoaster ride to become valuable insights.",
    "Rest assured, we're weaving a tale of insights from the threads of your data.",
    "Tick-tock, the clock is ticking while we extract precious insights from your data.",
    "While the gears turn, your data is transformed into a symphony of insights.",
    "Just a sec, we're putting your data through the insightification process.",
    "Hold your breath, we're giving your data a makeover to reveal its hidden insights.",
    "While the data marathon unfolds, the finish line of valuable insights draws near.",
    "Your data is like a jigsaw puzzle, and we're piecing together the insights.",
    "Behind the scenes, our data whisperers are turning your information into valuable gems.",
    "As we speak, your data is going through the alchemical transformation into insights.",
    "Please wait patiently, we're performing data origami to create beautiful insights.",
    "Please hold your horses, we're saddling up your data for an enlightening journey.",
    "Stay tuned! Your data is getting a makeover into valuable information.",
    "While the data wizards work their magic, your information is taking shape.",
    "Tick-tock, the clock is ticking as your data evolves into insightful information.",
    "Hang in there, we're unraveling the secrets of your data to create meaningful information.",
    "Your data is on a thrilling rollercoaster ride towards information enlightenment.",
    "Rest assured, we're unlocking the hidden vaults of information within your data.",
    "Just a moment, we're sculpting your data into a masterpiece of information.",
    "Don't fret! We're sifting through your data to extract sparkling nuggets of information.",
    "Sit back and relax while we weave your data into a tapestry of valuable information.",
    "Your data is on a transformative journey, emerging as a butterfly of information.",
    "Meanwhile, our data alchemists are turning your data into a goldmine of information.",
    "In the blink of an eye, your data is morphing into a symphony of information.",
    "As we speak, your data is being transmuted into a fountain of insightful information.",
    "Please wait patiently, we're distilling your data into a elixir of information.",
    "While the gears turn, your data is transformed into a mosaic of valuable information.",
    "Your data is like a puzzle, and we're piecing together the information it holds.",
    "Hold on tight, we're crafting your data into an information treasure trove.",
    "Behind the scenes, our data virtuosos are composing an opus of information.",
    "While the data marathon unfolds, the finish line of enlightening information draws near.",
    "Stay curious! Your data is being translated into a language of information.",
    "Please bear with us, we're sculpting your data into an information masterpiece.",
    "Your data is undergoing a metamorphosis, emerging as a beautiful butterfly of information.",
    "Meanwhile, our data sculptors are chiseling away to reveal the art of information in your data.",
    "As the data symphony plays on, a crescendo of valuable information is on its way.",
    "Just a sec, we're brewing a potent potion of information from your data.",
    "Sit tight, we're knitting together the threads of your data into a tapestry of information.",
    "While the data fairies work their magic, a sprinkle of valuable information is in the air.",
    "Your data is like a phoenix, rising from the ashes to become a source of enlightening information.",
    "Stay patient! Your data is undergoing a metamorphosis into a library of valuable information.",
    "Please hold on, we're decoding the secrets of your data to unlock pearls of information.",
    "Your data is on a scenic route to enlightenment, revealing snippets of valuable information.",
    "While the data orchestra plays, a harmonious melody of information is being composed.",
    "Keep calm and trust the process; your data is transforming into a reservoir of information.",
    "Please be patient, we're forging your data into a powerful tool for information discovery.",
    "Your data is like clay, and we're molding it into a sculpture of insightful information.",
    "While the data voyage continues, a treasure trove of information awaits your arrival.",
    "Hold your breath, we're conjuring a spell to reveal the hidden information in your data.",
    "Please hold tight, we're baking your data into a delicious pie of information.",
    "Your data is taking a dip in the pool of knowledge, emerging as a wellspring of information.",
    "While the data-dance unfolds, intricate patterns of information are being choreographed.",
    "Don't worry, your data is in safe hands as it's transformed into a beacon of information.",
    "Stay tuned! Your data is being transformed into a symphony of enlightening information.",
    "As we speak, your data is metamorphosing into a tapestry of valuable information.",
    "Please wait patiently, we're infusing your data with wisdom to create insightful information.",
    "While the data gears turn, the path to valuable information becomes clearer.",
    "Your data is like a library, and we're organizing it into a wealth of informational resources.",
    "Hang in there, we're deciphering the code to unlock the secrets of information within your data.",
    "Rest assured, your data is being polished into a gem of valuable information.",
    "Just a moment, we're weaving a story of information from the threads of your data.",
    "Don't fret, your data is on an adventure, uncovering pearls of information along the way.",
    "While the data particles dance, a tapestry of enlightening information takes shape.",
  ];
  var randomIndex = Math.floor(Math.random() * texts.length);
  return texts[randomIndex];
}
